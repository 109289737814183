import { all, call, put, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import budgetBalanceApi from 'api/BudgetBalanceApi'

import {
  GET_BUDGET_BALANCES_PREVIEW,
  IMPORT_BUDGET_BALANCES,
} from './constants'
import { clearBudgetData } from 'containers/Budget/actions'
import {
  getBudgetBalancesPreviewSuccess,
  importBudgetBalancesError,
  importBudgetBalancesSuccess,
} from './actions'

export function* importBudgetBalance(action) {
  const { importMode, ...parameters } = action
  try {
    if (importMode === 'singleDimensionTarget') {
      yield call(budgetBalanceApi.importBudgetBalance, parameters)
    } else {
      yield call(budgetBalanceApi.importBudgetBalanceAllDimensions, parameters)
    }
    yield put(importBudgetBalancesSuccess({}))
    yield put(clearBudgetData())
  } catch (error) {
    yield put(clearBudgetData())
    yield put(handleError(error, importBudgetBalancesError))
  }
}

export function* getBudgetBalancesPreview(action) {
  const { importMode, ...parameters } = action
  try {
    let data
    if (importMode === 'singleDimensionTarget') {
      data = yield call(budgetBalanceApi.getBudgetBalancesPreview, parameters)
    } else {
      data = yield call(
        budgetBalanceApi.getBudgetBalancesPreviewAllDimensions,
        parameters
      )
    }
    yield put(getBudgetBalancesPreviewSuccess({ data }))
  } catch (error) {
    yield put(handleError(error, importBudgetBalancesError))
  }
}

// All sagas to be loaded
export function* budgetSaga() {
  yield all([
    takeLatest(IMPORT_BUDGET_BALANCES, importBudgetBalance),
    takeLatest(GET_BUDGET_BALANCES_PREVIEW, getBudgetBalancesPreview),
  ])
}

export default budgetSaga
