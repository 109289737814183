/*
 *
 * Budgets constants
 *
 */
export const CREATE_BUDGET = 'app/Budgets/CREATE_BUDGET'
export const CREATE_BUDGET_ERROR = 'app/Budgets/CREATE_BUDGET_ERROR'
export const CREATE_BUDGET_SUCCESS = 'app/Budgets/CREATE_BUDGET_SUCCESS'

export const DELETE_BUDGET = 'app/Budgets/DELETE_BUDGET'
export const DELETE_BUDGET_ERROR = 'app/Budgets/DELETE_BUDGET_ERROR'
export const DELETE_BUDGET_SUCCESS = 'app/Budgets/DELETE_BUDGET_SUCCESS'

export const DELETE_PARTIAL_BUDGET = 'app/Budgets/DELETE_PARTIAL_BUDGET'
export const DELETE_PARTIAL_BUDGET_ERROR =
  'app/Budgets/DELETE_PARTIAL_BUDGET_ERROR'
export const DELETE_PARTIAL_BUDGET_SUCCESS =
  'app/Budgets/DELETE_PARTIAL_BUDGET_SUCCESS'

export const EDIT_BUDGET = 'app/Budgets/EDIT_BUDGET'
export const COPY_BUDGET = 'app/Budgets/COPY_BUDGET'

export const GET_BUDGETS = 'app/Budgets/GET_BUDGETS'
export const GET_BUDGETS_ERROR = 'app/Budgets/GET_BUDGETS_ERROR'
export const GET_BUDGETS_SUCCESS = 'app/Budgets/GET_BUDGETS_SUCCESS'

export const SET_DEFAULT_BUDGET = 'app/Budgets/SET_DEFAULT_BUDGET'
export const SET_DEFAULT_BUDGET_ERROR = 'app/Budgets/SET_DEFAULT_BUDGET_ERROR'
export const SET_DEFAULT_BUDGET_SUCCESS =
  'app/Budgets/SET_DEFAULT_BUDGET_SUCCESS'

export const UPDATE_BUDGET = 'app/Budgets/UPDATE_BUDGET'
export const UPDATE_BUDGET_ERROR = 'app/Budgets/UPDATE_BUDGET_ERROR'
export const UPDATE_BUDGET_SUCCESS = 'app/Budgets/UPDATE_BUDGET_SUCCESS'

export const CREATE_BUDGET_COPY = 'app/Budgets/CREATE_BUDGET_COPY'
export const CREATE_BUDGET_COPY_ERROR = 'app/Budgets/CREATE_BUDGET_COPY_ERROR'
export const CREATE_BUDGET_COPY_SUCCESS =
  'app/Budgets/CREATE_BUDGET_COPY_SUCCESS'

export const GET_BUDGET_FOLDERS = 'app/Budgets/GET_BUDGET_FOLDERS'
export const GET_BUDGET_FOLDERS_ERROR = 'app/Budgets/GET_BUDGET_FOLDERS_ERROR'
export const GET_BUDGET_FOLDERS_SUCCESS =
  'app/Budgets/GET_BUDGET_FOLDERS_SUCCESS'

export const CREATE_BUDGET_FOLDER = 'app/Budgets/CREATE_BUDGET_FOLDER'
export const CREATE_BUDGET_FOLDER_ERROR =
  'app/Budgets/CREATE_BUDGET_FOLDER_ERROR'
export const CREATE_BUDGET_FOLDER_SUCCESS =
  'app/Budgets/CREATE_BUDGET_FOLDER_SUCCESS'

export const DELETE_BUDGET_FOLDER = 'app/Budgets/DELETE_BUDGET_FOLDER'
export const DELETE_BUDGET_FOLDER_ERROR =
  'app/Budgets/DELETE_BUDGET_FOLDER_ERROR'
export const DELETE_BUDGET_FOLDER_SUCCESS =
  'app/Budgets/DELETE_BUDGET_FOLDER_SUCCESS'

export const EDIT_BUDGET_FOLDER = 'app/Budgets/EDIT_BUDGET_FOLDER'
export const EDIT_BUDGET_FOLDER_ERROR = 'app/Budgets/EDIT_BUDGET_FOLDER_ERROR'
export const EDIT_BUDGET_FOLDER_SUCCESS =
  'app/Budgets/EDIT_BUDGET_FOLDER_SUCCESS'
