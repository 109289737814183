import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { createPathPrefix } from 'utils/context'

// Implements System/Customer/Company level UserRoles

const createUserRole = ({ customerCode, companyCode, role, userId }) =>
  axios
    .put(
      `${createPathPrefix(
        customerCode,
        companyCode,
        true
      )}roles/${role}/users/${userId}`,
      {},
      config()
    )
    .then(returnData)
    .catch(catchError)

const getUserRoles = ({ userId, customerCode, companyCode }) =>
  axios
    .get(
      `${createPathPrefix(
        customerCode,
        companyCode,
        true
      )}users/${userId}/roles`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const deleteUserRole = ({ customerCode, companyCode, role, userId }) =>
  axios
    .delete(
      `${createPathPrefix(
        customerCode,
        companyCode,
        true
      )}roles/${role}/users/${userId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const userRolesApi = {
  createUserRole,
  deleteUserRole,
  getUserRoles,
}

export default userRolesApi
