import axios from 'axios'
import { catchError, config, returnData } from './api-utils'

const importBudgetBalance = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const importBudgetBalanceAllDimensions = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/allDimensions`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getBudgetBalancesPreview = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/preview`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const getBudgetBalancesPreviewAllDimensions = ({
  companyCode,
  budgetId,
  budgetSchemeRowId,
  preview,
  ...action
}) =>
  axios
    .post(
      `${companyCode}/budgets/${budgetId}/data/import/${budgetSchemeRowId}/allDimensions/preview`,
      action,
      config()
    )
    .then(returnData)
    .catch(catchError)

const budgetBalanceApi = {
  importBudgetBalance,
  importBudgetBalanceAllDimensions,
  getBudgetBalancesPreview,
  getBudgetBalancesPreviewAllDimensions,
}

export default budgetBalanceApi
