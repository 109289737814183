import { Record } from 'immutable'

const ReportSchemeRowUpdateRecord = Record({
  balanceType: '',
  companyLevelDimensions: [],
  children: null,
  dimensionCode: null,
  endAccount: undefined,
  formula: null,
  hideAccountIntervals: false,
  name: '',
  operativeItemDataType: '',
  previousMonthCount: 0,
  previousMonthCountType: '',
  reportSchemeRowType: 'header',
  rowFactor: 0,
  startAccount: undefined,
  visible: true,
  dataGroupId: '',
  dimensionValueFilters: [],
  dimensionFilters: [],
  reportingGroupFilters: [],
  discludedCompanies: [],
})

export default ReportSchemeRowUpdateRecord
