/*
 *
 * User reducer
 *
 */
import { fromJS, List } from 'immutable'
import { UserRoleRecord } from 'records'

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_ERROR,
  CREATE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_ERROR,
  DELETE_USER_ROLE_SUCCESS,
  GET_USER_ROLES,
  GET_USER_ROLES_ERROR,
  GET_USER_ROLES_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SET_VALIDITY_RANGE_ENABLED,
  TOGGLE_SHOW_CREATE_USER_ROLE_FORM,
  RESET_PASSWORD,
} from './constants'

const mapUserRole = ({ customerCode, companyCode, name, id, ...rest }) =>
  new UserRoleRecord({
    ...rest,
    id,
    customerCode,
    companyCode,
    name,
  })

const initialState = fromJS({
  error: false,
  loading: false,
  validityRangeEnabled: undefined,
  showCreateUserRoleForm: false,
  userRoles: undefined,
  userId: undefined,
})

function userReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_ROLE:
    case DELETE_USER_ROLE:
    case GET_USER_ROLES:
    case CHANGE_PASSWORD:
    case RESET_PASSWORD:
    case UPDATE_USER:
      return state.set('loading', true).set('error', false)

    case CHANGE_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return state.set('loading', false)

    case SET_VALIDITY_RANGE_ENABLED:
      return state.set('validityRangeEnabled', action.enabled)

    case DELETE_USER_ROLE_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'userRoles',
          state
            .get('userRoles')
            .filter((userRole) => userRole.id !== action.userRole.id)
        )

    case TOGGLE_SHOW_CREATE_USER_ROLE_FORM:
      return state.set(
        'showCreateUserRoleForm',
        !state.get('showCreateUserRoleForm')
      )

    case CREATE_USER_ROLE_SUCCESS:
      return state
        .set('loading', false)
        .set('showCreateUserRoleForm', false)
        .set(
          'userRoles',
          state.get('userRoles').push(UserRoleRecord(action.userRole))
        )

    case GET_USER_ROLES_SUCCESS:
      return state
        .set('loading', false)
        .set('userRoles', List(action.userRoles.map(mapUserRole)))
        .set('userId', action.userId)

    case CREATE_USER_ROLE_ERROR:
    case DELETE_USER_ROLE_ERROR:
    case GET_USER_ROLES_ERROR:
    case CHANGE_PASSWORD_ERROR:
    case UPDATE_USER_ERROR:
      return state.set('loading', false).set('error', action.error)

    default:
      return state
  }
}

export default userReducer
