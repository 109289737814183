export const USERNAME = 'userName'
export const CLIENT_ID = 'clientID'
export const CLIENT_SECRET = 'clientSecret'
export const PASSWORD = 'password'
export const APIKEY = 'apiKey'
export const URL = 'url'
export const DATABASE = 'database'
export const SCHEMA = 'schema'
export const COMPANY_IDENTIFIER = 'companyIdentifier'
export const SCOPE = 'scope'
export const ACCESS_TOKEN_URL = 'accessTokenUrl'

// If this is used it is posted to different endpoint.
//  Todo: send all to same and handle this different thing in backend router service
export const ACCESS_TOKEN = 'accessToken'

export const CREDENTIALS_SETTINGS = [
  USERNAME,
  PASSWORD,
  CLIENT_ID,
  CLIENT_SECRET,
  APIKEY,
  URL,
  DATABASE,
  SCHEMA,
  COMPANY_IDENTIFIER,
  ACCESS_TOKEN_URL,
  ACCESS_TOKEN,
  SCOPE,
]

export const PASSWORD_TYPE_CREDENTIALS = [
  PASSWORD,
  CLIENT_SECRET,
  APIKEY,
  ACCESS_TOKEN,
]

export const MATERIAL_TYPE_DIMENSIONS = 'DIMENSIONS'
export const MATERIAL_TYPE_OPERATIVE = 'OPERATIVEDATA'
export const MATERIAL_TYPE_VOUCHERS = 'VOUCHERS'
export const MATERIAL_TYPE_PURCHASE_INVOICES = 'PURCHASE_INVOICES'
export const MATERIAL_TYPE_SALES_INVOICES = 'SALES_INVOICES'
export const MATERIAL_TYPE_HOURS = 'HOURS'

export const INTEGRATION_MATERIAL_TYPES = [
  MATERIAL_TYPE_SALES_INVOICES,
  MATERIAL_TYPE_PURCHASE_INVOICES,
  MATERIAL_TYPE_DIMENSIONS,
  MATERIAL_TYPE_VOUCHERS,
  MATERIAL_TYPE_OPERATIVE,
  MATERIAL_TYPE_HOURS,
]

export const FORM_NAME_PREFIX_TO_PREVENT_AUTO_COMPLETE =
  'Integration-autofill-preventation-prefix-'

export const EXECUTE_INTEGRATION =
  'app/IntegrationConnections/EXECUTE_INTEGRATION'
export const EXECUTE_INTEGRATION_SUCCESS =
  'app/IntegrationConnections/EXECUTE_INTEGRATION_SUCCESS'
export const EXECUTE_INTEGRATION_ERROR =
  'app/IntegrationConnections/EXECUTE_INTEGRATION_ERROR'

export const GET_INTEGRATION_EVENTS =
  'app/IntegrationConnections/GET_INTEGRATION_EVENTS'
export const GET_INTEGRATION_EVENTS_SUCCESS =
  'app/IntegrationConnections/GET_INTEGRATION_EVENTS_SUCCESS'
export const GET_INTEGRATION_EVENTS_ERROR =
  'app/IntegrationConnections/GET_INTEGRATION_EVENTS_ERROR'

export const GET_INTEGRATION_CONNECTIONS =
  'app/IntegrationConnections/GET_INTEGRATION_CONNECTIONS'
export const GET_INTEGRATION_CONNECTIONS_ERROR =
  'app/IntegrationConnections/GET_INTEGRATION_CONNECTIONS_ERROR'
export const GET_INTEGRATION_CONNECTIONS_SUCCESS =
  'app/IntegrationConnections/GET_INTEGRATION_CONNECTIONS_SUCCESS'

export const CREATE_INTEGRATION_CONNECTION =
  'app/IntegrationConnections/CREATE_INTEGRATION_CONNECTION'
export const CREATE_INTEGRATION_CONNECTION_ERROR =
  'app/IntegrationConnections/CREATE_INTEGRATION_CONNECTION_ERROR'
export const PUT_INTEGRATION_CONNECTION =
  'app/IntegrationConnections/PUT_INTEGRATION_CONNECTION'
export const PUT_INTEGRATION_CONNECTION_SUCCESS =
  'app/IntegrationConnections/PUT_INTEGRATION_CONNECTION_SUCCESS'
export const PUT_INTEGRATION_CONNECTION_ERROR =
  'app/IntegrationConnections/PUT_INTEGRATION_CONNECTION_ERROR'

export const DELETE_INTEGRATION_CONNECTION =
  'app/IntegrationConnections/DELETE_INTEGRATION_CONNECTION'
export const DELETE_INTEGRATION_CONNECTION_SUCCESS =
  'app/IntegrationConnections/DELETE_INTEGRATION_CONNECTION_SUCCESS'
export const DELETE_INTEGRATION_CONNECTION_ERROR =
  'app/IntegrationConnections/DELETE_INTEGRATION_CONNECTION_ERROR'
