import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { createPathPrefix } from 'utils/context'

const createRole = ({ customerCode, companyCode, roleName }) =>
  axios
    .post(
      `${createPathPrefix(customerCode, companyCode, true)}roles`,
      { name: roleName },
      config()
    )
    .then(returnData)
    .catch(catchError)

const editRole = ({ customerCode, companyCode, roleName, editedRoleName }) => {
  return axios
    .patch(
      `${createPathPrefix(customerCode, companyCode, true)}roles/${roleName}`,
      { name: editedRoleName },
      config()
    )
    .then(returnData)
    .catch(catchError)
}

const copyRole = ({ customerCode, companyCode, roleName, copyRoleName }) => {
  return axios
    .post(
      `${createPathPrefix(
        customerCode,
        companyCode,
        true
      )}roles/${roleName}/copy`,
      { name: copyRoleName },
      config()
    )
    .then(returnData)
    .catch(catchError)
}

const getCompanyRoles = (customerCode, companyCode) =>
  axios
    .get(`${createPathPrefix(customerCode, companyCode, true)}roles`, config())
    .then(returnData)
    .catch(catchError)

const deleteRole = ({ customerCode, companyCode, roleName }) =>
  axios
    .delete(
      `${createPathPrefix(customerCode, companyCode, true)}roles/${roleName}`,
      config()
    )
    .then(returnData)
    .catch(catchError)

const rolesApi = {
  createRole,
  deleteRole,
  getCompanyRoles,
  editRole,
  copyRole,
}

export default rolesApi
