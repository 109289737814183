import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import budgetApi from 'api/BudgetApi'

import {
  createBudgetError,
  createBudgetSuccess,
  deleteBudgetError,
  deleteBudgetSuccess,
  getBudgetsError,
  getBudgetsSuccess,
  getBudgetFoldersSuccess,
  getBudgetFoldersError,
  updateBudgetError,
  updateBudgetSuccess,
  createBudgetCopyError,
  createBudgetCopySuccess,
  createBudgetFolderSuccess,
  createBudgetFolderError,
  deletePartialBudgetError,
  deletePartialBudgetSuccess,
  setDefaultBudgetError,
  setDefaultBudgetSuccess,
  deleteBudgetFolderSuccess,
  deleteBudgetFolderError,
  editBudgetFolderError,
  editBudgetFolderSuccess,
} from './actions'
import { deleteSubBudgetsSuccess } from '../SubBudgets/actions'
import {
  CREATE_BUDGET,
  CREATE_BUDGET_COPY,
  DELETE_BUDGET_FOLDER,
  DELETE_BUDGET,
  GET_BUDGETS,
  SET_DEFAULT_BUDGET,
  UPDATE_BUDGET,
  DELETE_PARTIAL_BUDGET,
  GET_BUDGET_FOLDERS,
  CREATE_BUDGET_FOLDER,
  EDIT_BUDGET_FOLDER,
} from './constants'

const UpdateBudgetRecord = Record({
  budgetType: null,
  endDate: null,
  lockDate: null,
  name: undefined,
  startDate: null,
  isDefault: null,
  useAccountingLockDate: null,
  realBalanceEndDate: null,
  presentationType: undefined,
  displayDecimalsAmount: null,
  timeRangeStart: null,
  timeRangeEnd: null,
  removeDimensionsFromBalanceSheet: null,
  dimensionIdWhitelist: null,
  budgetFolderIds: null,
})

// Individual exports for testing
export function* createBudget(action) {
  const {
    accountSchemeId,
    budgetType,
    companyCode,
    endDate,
    displayDecimalsAmount,
    lockDate,
    name,
    startDate,
    budgetFolderIds,
    useAccountingLockDate,
    presentationType,
    realBalanceEndDate,
    timeRangeEnd,
    timeRangeStart,
    generateAssets,
    generateProfitAndLoss,
    generateProfitAndLossUsingMonths,
    removeDimensionsFromBalanceSheet,
    dimensionIdWhitelist,
  } = action
  try {
    const budget = yield call(budgetApi.createBudget, {
      accountSchemeId,
      budgetType,
      companyCode,
      displayDecimalsAmount,
      endDate,
      lockDate,
      name,
      budgetFolderIds,
      startDate,
      useAccountingLockDate,
      presentationType,
      realBalanceEndDate,
      timeRangeEnd,
      timeRangeStart,
      generateAssets,
      generateProfitAndLoss,
      generateProfitAndLossUsingMonths,
      removeDimensionsFromBalanceSheet,
      dimensionIdWhitelist,
    })
    yield put(createBudgetSuccess({ budget, companyCode }))
  } catch (error) {
    yield put(handleError(error, createBudgetError))
  }
}

export function* createBudgetFolder(action) {
  const { companyCode, name } = action
  try {
    const budgetFolder = yield call(budgetApi.createBudgetFolder, {
      companyCode,
      name,
    })
    yield put(createBudgetFolderSuccess({ budgetFolder, companyCode }))
  } catch (error) {
    yield put(handleError(error, createBudgetFolderError))
  }
}

export function* createBudgetCopy(action) {
  const {
    budgetId,
    companyCode,
    name,
    copyUserRights,
    copySubBudgets,
    copyData,
    copyNotes,
    copySubBudgetNotes,
    copySubBudgetRollingRules,
    copySubBudgetTags,
    copySubBudgetData,
    moveForMonths,
    dimensionIdWhitelist,
  } = action

  try {
    const budget = yield call(budgetApi.copyBudget, {
      budgetId,
      companyCode,
      name,
      copyUserRights,
      copySubBudgets,
      copyData,
      copyNotes,
      copySubBudgetNotes,
      copySubBudgetRollingRules,
      copySubBudgetTags,
      copySubBudgetData,
      moveForMonths,
      dimensionIdWhitelist,
    })
    yield put(createBudgetCopySuccess({ budget, companyCode }))
  } catch (error) {
    yield put(handleError(error, createBudgetCopyError))
  }
}

export function* deleteBudget(action) {
  const { companyCode, id } = action
  try {
    yield call(budgetApi.deleteBudget, { companyCode, id })
    yield put(deleteBudgetSuccess({ companyCode, id }))
  } catch (error) {
    yield put(handleError(error, deleteBudgetError))
  }
}

export function* editBudgetFolder(action) {
  const { companyCode, id, name } = action
  try {
    const folder = yield call(budgetApi.editBudgetFolder, {
      companyCode,
      id,
      name,
    })
    yield put(editBudgetFolderSuccess({ folder, companyCode }))
  } catch (error) {
    yield put(handleError(error, editBudgetFolderError))
  }
}

export function* deleteBudgetFolder(action) {
  const { companyCode, id } = action
  try {
    yield call(budgetApi.deleteBudgetFolder, { companyCode, id })
    yield put(deleteBudgetFolderSuccess({ companyCode, id }))
  } catch (error) {
    yield put(handleError(error, deleteBudgetFolderError))
  }
}

export function* deletePartialBudget(action) {
  const { budgetId, companyCode, deleteTargets } = action
  try {
    yield call(budgetApi.deletePartialBudget, {
      budgetId,
      companyCode,
      deleteTargets,
    })
    yield put(deletePartialBudgetSuccess({ budgetId, companyCode }))
    if (deleteTargets.subBudgets) {
      yield put(deleteSubBudgetsSuccess({ budgetId }))
    }
  } catch (error) {
    yield put(handleError(error, deletePartialBudgetError))
  }
}

export function* updateBudget(action) {
  const { budget, companyCode, updatedValues } = action
  const patch = Diff(
    new UpdateBudgetRecord(budget),
    new UpdateBudgetRecord(updatedValues)
  ).toJS()
  try {
    const updatedBudget = yield call(budgetApi.patchBudget, {
      budgetId: budget.id,
      companyCode,
      patch,
    })
    yield put(updateBudgetSuccess({ budget: updatedBudget, companyCode }))
  } catch (error) {
    yield put(handleError(error, updateBudgetError))
  }
}

export function* getBudgets(action) {
  const { companyCode } = action
  try {
    const budgets = yield call(budgetApi.getBudgets, companyCode)
    yield put(getBudgetsSuccess({ budgets, companyCode }))
  } catch (error) {
    yield put(handleError(error, getBudgetsError))
  }
}

export function* getBudgetFolders(action) {
  const { companyCode } = action
  try {
    const folders = yield call(budgetApi.getBudgetFolders, companyCode)
    yield put(getBudgetFoldersSuccess({ folders, companyCode }))
  } catch (error) {
    yield put(handleError(error, getBudgetFoldersError))
  }
}

export function* setDefaultBudget({ companyCode, id }) {
  try {
    yield call(budgetApi.setDefault, { companyCode, id })
    yield put(setDefaultBudgetSuccess({ companyCode, id }))
  } catch (err) {
    yield put(handleError(err, setDefaultBudgetError))
  }
}

export function* budgetsSaga() {
  yield all([
    takeLatest(CREATE_BUDGET, createBudget),
    takeLatest(CREATE_BUDGET_FOLDER, createBudgetFolder),
    takeEvery(DELETE_BUDGET_FOLDER, deleteBudgetFolder),
    takeEvery(DELETE_BUDGET, deleteBudget),
    takeEvery(EDIT_BUDGET_FOLDER, editBudgetFolder),
    takeEvery(GET_BUDGETS, getBudgets),
    takeEvery(GET_BUDGET_FOLDERS, getBudgetFolders),
    takeEvery(UPDATE_BUDGET, updateBudget),
    takeEvery(CREATE_BUDGET_COPY, createBudgetCopy),
    takeEvery(DELETE_PARTIAL_BUDGET, deletePartialBudget),
    takeEvery(SET_DEFAULT_BUDGET, setDefaultBudget),
  ])
}

export default budgetsSaga
