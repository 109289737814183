/*
 *
 * Budget constants
 *
 */
export const ADD_BUDGET_ROW = 'app/Budget/ADD_BUDGET_ROW'
export const ADD_BUDGET_ROW_ERROR = 'app/Budget/ADD_BUDGET_ROW_ERROR'
export const ADD_BUDGET_ROW_SUCCESS = 'app/Budget/ADD_BUDGET_ROW_SUCCESS'

export const ADD_SUB_BUDGET_TAB = 'app/Budget/ADD_SUB_BUDGET_TAB'
export const CLOSE_SUB_BUDGET_TAB = 'app/Budget/CLOSE_SUB_BUDGET_TAB'

export const ADD_NOTE_TAB = 'app/Budget/ADD_NOTE_TAB'
export const CLOSE_NOTE_TAB = 'app/Budget/CLOSE_NOTE_TAB'

export const CLEAR_BUDGET_DATA = 'app/Budget/CLEAR_BUDGET_DATA'

export const EDIT_BUDGET_CELL = 'app/Budget/EDIT_BUDGET_CELL'
export const EDIT_BUDGET_CELL_PATCH = 'app/Budget/EDIT_BUDGET_CELL_PATCH'
export const EDIT_BUDGET_CELL_ERROR = 'app/Budget/EDIT_BUDGET_CELL_ERROR'
export const EDIT_BUDGET_CELL_SUCCESS = 'app/Budget/EDIT_BUDGET_CELL_SUCCESS'
export const SET_BUDGET_FILTERS = 'app/Budget/SET_BUDGET_FILTERS'

export const GET_BUDGET = 'app/Budget/GET_BUDGET'
export const GET_BUDGET_ERROR = 'app/Budget/GET_BUDGET_ERROR'
export const GET_BUDGET_SUCCESS = 'app/Budget/GET_BUDGET_SUCCESS'

export const GET_BUDGET_FIXED_DATA = 'app/Budget/GET_BUDGET_FIXED_DATA'
export const GET_BUDGET_FIXED_DATA_ERROR =
  'app/Budget/GET_BUDGET_FIXED_DATA_ERROR'
export const GET_BUDGET_FIXED_DATA_SUCCESS =
  'app/Budget/GET_BUDGET_FIXED_DATA_SUCCESS'

export const GET_BUDGET_TREE = 'app/Budget/GET_BUDGET_TREE'
export const GET_BUDGET_TREE_ERROR = 'app/Budget/GET_BUDGET_TREE_ERROR'
export const GET_BUDGET_TREE_SUCCESS = 'app/Budget/GET_BUDGET_TREE_SUCCESS'

export const REMOVE_BUDGET_ROW = 'app/Budget/REMOVE_BUDGET_ROW'
export const REMOVE_BUDGET_ROW_ERROR = 'app/Budget/REMOVE_BUDGET_ROW_ERROR'
export const REMOVE_BUDGET_ROW_SUCCESS = 'app/Budget/REMOVE_BUDGET_ROW_SUCCESS'

export const SAVE_BUDGET_ROW = 'app/Budget/SAVE_BUDGET_ROW'
export const SAVE_BUDGET_ROW_ERROR = 'app/Budget/SAVE_BUDGET_ROW_ERROR'
export const SAVE_BUDGET_ROW_SUCCESS = 'app/Budget/SAVE_BUDGET_ROW_SUCCESS'

export const SYNC_BUDGET = 'app/Budget/SYNC_BUDGET'
export const SYNC_BUDGET_ERROR = 'app/Budget/SYNC_BUDGET_ERROR'
export const SYNC_BUDGET_SUCCESS = 'app/Budget/SYNC_BUDGET_SUCCESS'

export const SHOW_ROW_NOTES = 'app/Budget/SHOW_ROW_NOTES'
export const SHOW_ROW_SETTINGS = 'app/Budget/SHOW_ROW_SETTINGS'

// FILTERS
export const FILTER_UNUSED_ACCOUNTS = 'app/Budget/FILTER_UNUSED_ACCOUNTS'
export const TOGGLE_BUDGET_MODAL = 'app/Budget/TOGGLE_BUDGET_MODAL'
export const TOGGLE_FIXED_COLUMN = 'app/Budget/TOGGLE_FIXED_COLUMN'

export const MONTHLY_PRESENTATION = 'Months'
export const TERTILE_PRESENTATION = 'Tertiles'
export const QUARTERLY_PRESENTATION = 'Quarters'
export const YEARLY_PRESENTATION = 'Years'

export const BUDGET_TYPE_FORECAST = 'Forecast'

export const PRESENTATION_TYPES = [
  MONTHLY_PRESENTATION,
  QUARTERLY_PRESENTATION,
  TERTILE_PRESENTATION,
  YEARLY_PRESENTATION,
]
