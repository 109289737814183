/*
 *
 * Roles constants
 *
 */
export const DEFAULT_ROLES = ['CompanyUser', 'CompanySuperUser']
export const GET_ROLES = 'app/Roles/GET_ROLES'
export const GET_ROLES_SUCCESS = 'app/Roles/GET_ROLES_SUCCESS'
export const GET_ROLES_ERROR = 'app/Roles/GET_ROLES_ERROR'

export const CREATE_ROLE = 'app/Roles/CREATE_ROLE'
export const CREATE_ROLE_ERROR = 'app/Roles/CREATE_ROLE_ERROR'
export const CREATE_ROLE_SUCCESS = 'app/Roles/CREATE_ROLE_SUCCESS'

export const COPY_ROLE = 'app/Roles/COPY_ROLE'
export const COPY_ROLE_ERROR = 'app/Roles/COPY_ROLE_ERROR'
export const COPY_ROLE_SUCCESS = 'app/Roles/COPY_ROLE_SUCCESS'

export const EDIT_ROLE = 'app/Roles/EDIT_ROLE'
export const EDIT_ROLE_SUCCESS = 'app/Roles/EDIT_ROLE_SUCCESS'
export const EDIT_ROLE_ERROR = 'app/Roles/EDIT_ROLE_ERROR'

export const DELETE_ROLE = 'app/Roles/DELETE_ROLE'
export const DELETE_ROLE_ERROR = 'app/Roles/DELETE_ROLE_ERROR'
export const DELETE_ROLE_SUCCESS = 'app/Roles/DELETE_ROLE_SUCCESS'

export const TOGGLE_SHOW_CREATE_ROLE_FORM =
  'app/Roles/TOGGLE_SHOW_CREATE_ROLE_FORM'
