/*
 *
 * Users actions
 *
 */

import {
  CLEAR_FILTER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  FILTER_USERS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  INVITE_USER,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  TOGGLE_SHOW_CREATE_USER_FORM,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  DELETE_AAD_USER,
  DELETE_AAD_USER_SUCCESS,
  DELETE_AAD_USER_ERROR,
  UNLINK_AAD_USER,
  UNLINK_AAD_USER_ERROR,
  UNLINK_AAD_USER_SUCCESS,
} from './constants'

export const createUser = (user, customerCode, companyCode, role) => ({
  type: CREATE_USER,
  user,
  customerCode,
  companyCode,
  role,
})

export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  user,
})

export const createUserError = (error) => ({
  type: CREATE_USER_ERROR,
  error,
})

export const getUsers = (customerCode, companyCode, role) => ({
  type: GET_USERS,
  customerCode,
  companyCode,
  role,
})

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  users,
})

export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  error,
})

export const getAllUsers = () => ({
  type: GET_ALL_USERS,
})

export const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USERS_SUCCESS,
  users,
})

export const getAllUsersError = (error) => ({
  type: GET_ALL_USERS_ERROR,
  error,
})

export const deleteAADUser = (userId) => ({
  type: DELETE_AAD_USER,
  userId,
})

export const deleteAADUserSuccess = (userId) => ({
  type: DELETE_AAD_USER_SUCCESS,
  userId,
})

export const deleteAADUserError = (error) => ({
  type: DELETE_AAD_USER_ERROR,
  error,
})
export const inviteUser = (user, email) => ({
  type: INVITE_USER,
  user,
  email,
})
export const inviteUserSuccess = (users) => ({
  type: INVITE_USER_SUCCESS,
  users,
})
export const inviteUserError = (error) => ({
  type: INVITE_USER_ERROR,
  error,
})
export const unlinkAADUser = (userId, allUsersView) => ({
  type: UNLINK_AAD_USER,
  userId,
  allUsersView,
})

export const unlinkAADUserSuccess = (userId, allUsersView) => ({
  type: UNLINK_AAD_USER_SUCCESS,
  userId,
  allUsersView,
})

export const unlinkAADUserError = (error) => ({
  type: UNLINK_AAD_USER_ERROR,
  error,
})

export const deleteUser = (userId, customerCode, companyCode, role) => ({
  type: DELETE_USER,
  userId,
  customerCode,
  companyCode,
  role,
})

export const deleteUserSuccess = (userId) => ({
  type: DELETE_USER_SUCCESS,
  userId,
})

export const deleteUserError = (error) => ({
  type: DELETE_USER_ERROR,
  error,
})

export const toggleShowCreateUserForm = () => ({
  type: TOGGLE_SHOW_CREATE_USER_FORM,
})

export const filterUsers = (filteredUsers) => ({
  type: FILTER_USERS,
  filteredUsers,
})

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})
