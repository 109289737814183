import {
  CANCEL_ACCOUNT_CONVERSION,
  CHOSE_PROFILE,
  CHOOSE_PROFILE,
  GET_LOGGED_IN_USER_INFO,
  GET_LOGGED_IN_USER_INFO_ERROR,
  GET_PERMISSIONS_SUCCESS,
  GET_SUB_USERS_ERROR,
  GET_SUB_USERS_SUCCESS,
  INVITE_ERROR,
  PASSWORD_LOGIN,
  PASSWORD_LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  CHECK_LOGIN,
  SEND_INVITE,
  START_USER_ACCOUNT_CONVERSION,
  REMOVE_AAD_PROFILE,
  GET_LOGIN_METHOD,
  AAD_LOGIN_ERROR,
  INITIALIZE_LEMONSOFT_USER,
  INITIALIZE_LEMONSOFT_USER_SUCCESS,
  INITIALIZE_LEMONSOFT_USER_ERROR,
  SELECT_LEMONSOFT_ORGANIZATION,
  INITIALIZE_LEMONSOFT_USER_PROGRESS,
} from './constants'

export const choseProfile = (profile, navigate) => ({
  navigate,
  type: CHOSE_PROFILE,
  profile,
})
export const chooseProfile = (userData, navigate) => ({
  navigate,
  type: CHOOSE_PROFILE,
  userData,
})
export const getLoggedInUserInfo = ({ navigate }) => ({
  type: GET_LOGGED_IN_USER_INFO,
  navigate,
})
export const getLoggedInUserInfoError = (error) => ({
  type: GET_LOGGED_IN_USER_INFO_ERROR,
  error,
})
export const getPermissionsSuccess = (permissions) => ({
  type: GET_PERMISSIONS_SUCCESS,
  permissions,
})
export const getSubUsersSuccess = (user) => ({
  type: GET_SUB_USERS_SUCCESS,
  user,
})
export const getSubUsersError = (error) => ({
  type: GET_SUB_USERS_ERROR,
  error,
})

export const passwordLogin = ({ username, password, navigate }) => ({
  type: PASSWORD_LOGIN,
  username,
  password,
  navigate,
})
export const passwordLoginError = (error) => ({
  type: PASSWORD_LOGIN_ERROR,
  error,
})
export const aadLoginError = (error) => ({
  type: AAD_LOGIN_ERROR,
  error,
})
export const cancelAccountConversion = (navigate) => ({
  type: CANCEL_ACCOUNT_CONVERSION,
  navigate,
})

export const inviteError = (error) => ({ type: INVITE_ERROR, error })

export const loginSuccess = (user) => ({ type: LOGIN_SUCCESS, user })
export const logout = (navigate) => ({ type: LOGOUT, navigate })
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })
export const logoutError = () => ({ type: LOGOUT_ERROR })
export const checkLogin = ({ navigate }) => ({
  type: CHECK_LOGIN,
  navigate,
})

export const sendInvite = (inviteEmail) => ({
  type: SEND_INVITE,
  inviteEmail,
})
export const startUserAccountConversion = (forceConversion) => ({
  type: START_USER_ACCOUNT_CONVERSION,
  forceConversion,
})

export const removeAADProfile = (userId, aad) => ({
  type: REMOVE_AAD_PROFILE,
  userId,
  aad,
})

export const getLoginMethod = () => ({ type: GET_LOGIN_METHOD })

export const initializeLemonsoftUser = ({ navigate, organizationId }) => ({
  type: INITIALIZE_LEMONSOFT_USER,
  organizationId,
  navigate,
})

export const initializeLemonsoftUserSuccess = () => ({
  type: INITIALIZE_LEMONSOFT_USER_SUCCESS,
})

export const initializeLemonsoftUserError = (error) => ({
  type: INITIALIZE_LEMONSOFT_USER_ERROR,
  error,
})

export const selectLemonsoftOrganization = (organizations) => ({
  type: SELECT_LEMONSOFT_ORGANIZATION,
  organizations,
})

export const initializeLemonsoftUserProgress = (progress) => ({
  type: INITIALIZE_LEMONSOFT_USER_PROGRESS,
  progress,
})
