import patch from 'immpatch'
import { fromJS, List, Map, Set } from 'immutable'
import { isArray } from 'lodash'
import mapValues from 'lodash/mapValues'

import {
  ADD_BUDGET_ROW,
  ADD_BUDGET_ROW_ERROR,
  ADD_BUDGET_ROW_SUCCESS,
  ADD_SUB_BUDGET_TAB,
  CLOSE_SUB_BUDGET_TAB,
  CLEAR_BUDGET_DATA,
  EDIT_BUDGET_CELL,
  EDIT_BUDGET_CELL_ERROR,
  EDIT_BUDGET_CELL_SUCCESS,
  GET_BUDGET,
  GET_BUDGET_ERROR,
  GET_BUDGET_SUCCESS,
  GET_BUDGET_FIXED_DATA,
  GET_BUDGET_FIXED_DATA_ERROR,
  GET_BUDGET_FIXED_DATA_SUCCESS,
  GET_BUDGET_TREE_ERROR,
  // GET_BUDGET_TREE_SUCCESS,
  REMOVE_BUDGET_ROW_ERROR,
  REMOVE_BUDGET_ROW_SUCCESS,
  SAVE_BUDGET_ROW,
  SAVE_BUDGET_ROW_ERROR,
  SAVE_BUDGET_ROW_SUCCESS,
  SHOW_ROW_NOTES,
  SHOW_ROW_SETTINGS,
  SET_BUDGET_FILTERS,
  SYNC_BUDGET,
  SYNC_BUDGET_ERROR,
  SYNC_BUDGET_SUCCESS,
  TOGGLE_BUDGET_MODAL,
  CLOSE_NOTE_TAB,
  ADD_NOTE_TAB,
  TOGGLE_FIXED_COLUMN,
} from './constants'
import {
  BudgetDataRecord,
  BudgetFixedDataRecord,
  BudgetValueRecord,
  ColumnSpecRecord,
  PeriodRecord,
  SchemeGroupRecord,
} from 'records'
import { mapBudgetTree } from './functions'

export const createBudgetData = (data) =>
  new BudgetDataRecord({
    columns: mapBudgetColumns(data.columns),
    rows: mapBudgetRows(data.rows),
  })

export const getBudgetMonthRange = (data) => {
  const months = data.columns.filter((child) => child.type === 'Month')
  const start = months[0].id
  const end = months[months.length - 1].id

  return { start, end }
}

// normalize budget data query params
const getBudgetDataQueryForAction = ({
  companyCode,
  budgetId,
  start,
  end,
  dv = [],
  view = 'All',
}) =>
  Map({
    companyCode,
    budgetId,
    start,
    end,
    dv: Array.isArray(dv) ? dv : [dv],
    view,
  })

const mapBudgetColumns = (columns) =>
  List(
    columns.map(
      (col) =>
        new ColumnSpecRecord({
          ...col,
          period: new PeriodRecord(col.period),
        })
    )
  )

export const mapBudgetRows = (rows) =>
  Map(
    mapValues(rows, (row) =>
      Map(mapValues(row, (col) => new BudgetValueRecord(col)))
    )
  )

const initialState = fromJS({
  activeRow: undefined,
  activeCell: undefined,
  budgetId: undefined,
  data: undefined,
  error: false,
  fixedData: {
    data: new BudgetFixedDataRecord(),
    error: false,
    loading: false,
  },
  filters: [],
  loading: false,
  monthRange: {
    start: null,
    end: null,
  },
  query: undefined,
  rowNotes: {
    show: false,
    row: undefined,
  },
  rowSettings: {
    show: false,
    parentGroup: undefined,
    row: undefined,
    loading: false,
  },
  budgetModalType: null,
  shownSubBudgetTabs: Map(),
  shownNoteTabs: Map(),
  showFixedColumn: false,
  tree: new SchemeGroupRecord(),
  // shownSubBudgetTabs is datastructure of a following kind:
  // {
  //  { budgetId: "1", dimensionValueIds: ["4","1"] }: ["1", "3"],
  //  { budgetId: "2", dimensionValueIds: []}: ["2"],
  // }
  // where the values of the map are list of subBudgetIds.
  // The keys are SubBudgetTabRecords.
})

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUDGET_ROW:
    case SAVE_BUDGET_ROW:
      return state
        .setIn(['rowSettings', 'loading'], true)
        .setIn(['rowSettings', 'error'], false)

    case SET_BUDGET_FILTERS:
      return state.set('filters', action.filters)

    case ADD_BUDGET_ROW_ERROR:
    case EDIT_BUDGET_CELL_ERROR:
    case GET_BUDGET_ERROR:
    case GET_BUDGET_TREE_ERROR:
    case REMOVE_BUDGET_ROW_ERROR:
    case SYNC_BUDGET_ERROR:
      return state
        .set('loading', false)
        .setIn(['rowSettings', 'show'], false)
        .set('error', action.error)

    case SAVE_BUDGET_ROW_ERROR:
      return state
        .setIn(['rowSettings', 'loading'], false)
        .setIn(['rowSettings', 'error'], action.error)

    case ADD_BUDGET_ROW_SUCCESS:
    case SAVE_BUDGET_ROW_SUCCESS: {
      const dataShouldUpdate = Boolean(action.data)
      return state
        .set(
          'tree',
          mapBudgetTree(action.tree.schemeGroup, state.get('query').toJS())
        )
        .set(
          'data',
          dataShouldUpdate ? createBudgetData(action.data) : state.get('data')
        )
        .setIn(['rowSettings', 'loading'], false)
        .setIn(['rowSettings', 'show'], false)
    }

    case ADD_SUB_BUDGET_TAB: {
      const { budgetId } = action

      return state.updateIn(['shownSubBudgetTabs', budgetId], (subBudgets) => {
        if (!subBudgets) {
          return Set([action.subBudgetId])
        }

        return subBudgets.add(action.subBudgetId)
      })
    }

    case CLOSE_SUB_BUDGET_TAB: {
      const { budgetId } = action

      return state.updateIn(['shownSubBudgetTabs', budgetId], (subBudgets) =>
        subBudgets ? subBudgets.delete(action.subBudgetId) : Set([])
      )
    }

    case ADD_NOTE_TAB: {
      const { contentId } = action
      return state.setIn(['shownNoteTabs', String(contentId)], true)
    }

    case CLOSE_NOTE_TAB: {
      const { contentId } = action
      return state.deleteIn(['shownNoteTabs', String(contentId)])
    }

    case GET_BUDGET_FIXED_DATA_ERROR:
      return state
        .setIn(['fixedData', 'loading'], false)
        .setIn(['fixedData', 'error'], action.error)

    case GET_BUDGET_FIXED_DATA:
      return state
        .setIn(['fixedData', 'loading'], true)
        .setIn(['fixedData', 'error'], false)

    case GET_BUDGET_FIXED_DATA_SUCCESS:
      return state
        .setIn(['fixedData', 'loading'], false)
        .setIn(
          ['fixedData', 'data'],
          new BudgetFixedDataRecord({ rows: Map(action.rows) })
        )

    case EDIT_BUDGET_CELL_SUCCESS:
      return state.set('data', patch(state.get('data'), action.data))

    case CLEAR_BUDGET_DATA:
      return state.set('data', undefined).set('loading', false)
    case GET_BUDGET: {
      // Save the query for current getBudget action.
      // This is needed when determining editability of rows.
      const query = getBudgetDataQueryForAction(action)
      return state
        .set('budgetId', action.budgetId)
        .set('query', query)
        .set('loading', true)
        .set('error', false)
    }

    case GET_BUDGET_SUCCESS:
    case SYNC_BUDGET_SUCCESS:
      return state
        .set(
          'tree',
          mapBudgetTree(action.tree.schemeGroup, state.get('query').toJS())
        )
        .set('data', createBudgetData(action.data))
        .set('monthRange', getBudgetMonthRange(action.data))
        .set('loading', false)

    case REMOVE_BUDGET_ROW_SUCCESS:
      return state
        .set(
          'tree',
          mapBudgetTree(action.tree.schemeGroup, state.get('query').toJS())
        )
        .set('loading', false)

    // TODO: update optimistically store!
    case EDIT_BUDGET_CELL: {
      const { selection, value } = action
      const hasDifferentValueForEachCell = isArray(value)
      const cells = hasDifferentValueForEachCell ? value : selection
      return cells.reduce((working, cell) => {
        const valueToSet = hasDifferentValueForEachCell ? cell.value : value
        return working.setIn(
          ['data', 'rows', cell.row, cell.column, 'amount'],
          valueToSet
        )
      }, state)
    }

    case SHOW_ROW_NOTES:
      return state
        .setIn(['rowNotes', 'row'], action.row)
        .setIn(['rowNotes', 'show'], action.show)

    case SHOW_ROW_SETTINGS:
      return state
        .setIn(['rowSettings', 'row'], action.row)
        .setIn(['rowSettings', 'parentGroup'], action.parentGroup)
        .setIn(['rowSettings', 'loading'], false)
        .setIn(['rowSettings', 'show'], action.show)
        .setIn(['rowSettings', 'error'], false)

    case SYNC_BUDGET:
      return state.set('loading', true)

    case TOGGLE_BUDGET_MODAL:
      return state.set('budgetModalType', action.modalType)

    case TOGGLE_FIXED_COLUMN:
      return state.set('showFixedColumn', !state.get('showFixedColumn'))

    default:
      return state
  }
}

export default budgetReducer
