/*
 *
 * Roles actions
 *
 */

import {
  TOGGLE_SHOW_CREATE_ROLE_FORM,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  COPY_ROLE,
  COPY_ROLE_SUCCESS,
  COPY_ROLE_ERROR,
  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_ERROR,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
} from './constants'

export const toggleShowCreateRoleForm = () => ({
  type: TOGGLE_SHOW_CREATE_ROLE_FORM,
})

export const getRoles = (customerCode, companyCode) => ({
  type: GET_ROLES,
  customerCode,
  companyCode,
})

export const getRolesSuccess = (roles, companyCode, customerCode) => ({
  type: GET_ROLES_SUCCESS,
  roles,
  companyCode,
  customerCode,
})

export const getRolesError = (error) => ({
  type: GET_ROLES_ERROR,
  error,
})

export const createRole = ({ customerCode, companyCode, roleName }) => ({
  type: CREATE_ROLE,
  customerCode,
  companyCode,
  roleName,
})

export const createRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: CREATE_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const createRoleError = (error) => ({
  type: CREATE_ROLE_ERROR,
  error,
})

export const copyRole = ({
  customerCode,
  companyCode,
  roleName,
  copyRoleName,
}) => ({
  type: COPY_ROLE,
  customerCode,
  companyCode,
  roleName,
  copyRoleName,
})

export const copyRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: COPY_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const copyRoleError = (error) => ({
  type: COPY_ROLE_ERROR,
  error,
})

export const editRole = ({
  customerCode,
  companyCode,
  roleName,
  editedRoleName,
}) => ({
  type: EDIT_ROLE,
  customerCode,
  companyCode,
  roleName,
  editedRoleName,
})

export const editRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: EDIT_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const editRoleError = (error) => ({
  type: EDIT_ROLE_ERROR,
  error,
})

export const deleteRole = ({ customerCode, companyCode, roleName }) => ({
  type: DELETE_ROLE,
  customerCode,
  companyCode,
  roleName,
})

export const deleteRoleSuccess = ({ customerCode, companyCode, roleName }) => ({
  type: DELETE_ROLE_SUCCESS,
  customerCode,
  companyCode,
  roleName,
})

export const deleteRoleError = (error) => ({
  type: DELETE_ROLE_ERROR,
  error,
})
