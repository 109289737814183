import { Record } from 'immutable'

const ReportSchemeRowGroupRecord = Record({
  accountGroupType: '',
  balanceType: '',
  companyLevelDimensions: [],
  children: undefined,
  comment: null,
  dimensionCode: null,
  editable: false,
  endAccount: null,
  formula: null,
  hideAccountIntervals: false,
  id: 1,
  isCollapsed: true,
  level: undefined,
  name: '',
  operativeItemDataType: '',
  order: 0,
  parentId: undefined,
  parentRowId: undefined,
  path: '',
  previousMonthCount: 0,
  previousMonthCountType: '',
  reportSchemeRowType: '',
  rowFactor: 0,
  rowId: '',
  rowType: null,
  showForm: undefined,
  startAccount: null,
  type: '',
  valueMeters: undefined,
  visible: true,
  dataGroupId: '',
  dimensionValueFilters: [],
  dimensionFilters: [],
  reportingGroupFilters: [],
  discludedCompanies: [],
})

export default ReportSchemeRowGroupRecord
