/*
 *
 * Budget actions
 *
 */

import {
  ADD_BUDGET_ROW,
  ADD_BUDGET_ROW_ERROR,
  ADD_BUDGET_ROW_SUCCESS,
  ADD_SUB_BUDGET_TAB,
  CLOSE_SUB_BUDGET_TAB,
  CLEAR_BUDGET_DATA,
  EDIT_BUDGET_CELL,
  EDIT_BUDGET_CELL_ERROR,
  EDIT_BUDGET_CELL_PATCH,
  EDIT_BUDGET_CELL_SUCCESS,
  GET_BUDGET,
  GET_BUDGET_ERROR,
  GET_BUDGET_SUCCESS,
  GET_BUDGET_FIXED_DATA,
  GET_BUDGET_FIXED_DATA_ERROR,
  GET_BUDGET_FIXED_DATA_SUCCESS,
  GET_BUDGET_TREE,
  GET_BUDGET_TREE_ERROR,
  GET_BUDGET_TREE_SUCCESS,
  REMOVE_BUDGET_ROW,
  REMOVE_BUDGET_ROW_ERROR,
  REMOVE_BUDGET_ROW_SUCCESS,
  SAVE_BUDGET_ROW,
  SAVE_BUDGET_ROW_ERROR,
  SAVE_BUDGET_ROW_SUCCESS,
  SET_BUDGET_FILTERS,
  SHOW_ROW_NOTES,
  SHOW_ROW_SETTINGS,
  SYNC_BUDGET,
  SYNC_BUDGET_ERROR,
  SYNC_BUDGET_SUCCESS,
  TOGGLE_BUDGET_MODAL,
  ADD_NOTE_TAB,
  CLOSE_NOTE_TAB,
  TOGGLE_FIXED_COLUMN,
} from './constants'

export const addBudgetRow = ({
  companyCode,
  budgetId,
  parentRow,
  value,
  dv,
  end,
  start,
  view,
}) => ({
  type: ADD_BUDGET_ROW,
  companyCode,
  budgetId,
  parentRow,
  value,
  dv,
  end,
  start,
  view,
})
export const addBudgetRowError = (error) => ({
  type: ADD_BUDGET_ROW_ERROR,
  error,
})
export const addBudgetRowSuccess = ({ data, tree }) => ({
  type: ADD_BUDGET_ROW_SUCCESS,
  data,
  tree,
})

export const setFilters = ({ filters }) => ({
  type: SET_BUDGET_FILTERS,
  filters,
})

export const addSubBudgetTab = ({ budgetId, subBudgetId }) => ({
  type: ADD_SUB_BUDGET_TAB,
  budgetId,
  subBudgetId,
})

export const closeSubBudgetTab = ({ budgetId, subBudgetId }) => ({
  type: CLOSE_SUB_BUDGET_TAB,
  budgetId,
  subBudgetId,
})

export const addNoteTab = (contentId) => ({
  type: ADD_NOTE_TAB,
  contentId,
})

export const closeNoteTab = (contentId) => ({ type: CLOSE_NOTE_TAB, contentId })

export const editBudgetCell = ({
  budgetId,
  companyCode,
  selection,
  value,
  dv,
  start,
  end,
  rowHasData,
}) => ({
  type: EDIT_BUDGET_CELL,
  budgetId,
  companyCode,
  selection,
  value,
  dv,
  start,
  end,
  rowHasData,
})
export const editBudgetCellPatch = ({
  budgetId,
  companyCode,
  patches,
  dv,
  start,
  end,
  rowHasData,
}) => ({
  type: EDIT_BUDGET_CELL_PATCH,
  budgetId,
  companyCode,
  patches,
  dv,
  start,
  end,
  rowHasData,
})
export const editBudgetCellError = (error) => ({
  type: EDIT_BUDGET_CELL_ERROR,
  error,
})
export const editBudgetCellSuccess = ({ data }) => ({
  type: EDIT_BUDGET_CELL_SUCCESS,
  data,
})

export const getBudget = ({ budgetId, companyCode, dv, end, start, view }) => ({
  type: GET_BUDGET,
  budgetId,
  companyCode,
  dv,
  end,
  start,
  view,
})
export const getBudgetError = (error) => ({ type: GET_BUDGET_ERROR, error })
export const getBudgetSuccess = ({ data, tree }) => ({
  type: GET_BUDGET_SUCCESS,
  data,
  tree,
})

export const clearBudgetData = () => ({
  type: CLEAR_BUDGET_DATA,
})

export const getBudgetFixedData = ({ companyCode, budgetId }) => ({
  type: GET_BUDGET_FIXED_DATA,
  companyCode,
  budgetId,
})
export const getBudgetFixedDataError = (error) => ({
  type: GET_BUDGET_FIXED_DATA_ERROR,
  error,
})
export const getBudgetFixedDataSuccess = ({ rows }) => ({
  type: GET_BUDGET_FIXED_DATA_SUCCESS,
  rows,
})

export const getBudgetTree = ({ budgetId, companyCode }) => ({
  type: GET_BUDGET_TREE,
  budgetId,
  companyCode,
})
export const getBudgetTreeError = (error) => ({
  type: GET_BUDGET_TREE_ERROR,
  error,
})
export const getBudgetTreeSuccess = ({ budgetId, companyCode, tree }) => ({
  type: GET_BUDGET_TREE_SUCCESS,
  budgetId,
  companyCode,
  tree,
})

export const removeBudgetRow = ({ budgetId, companyCode, path, rowId }) => ({
  type: REMOVE_BUDGET_ROW,
  budgetId,
  companyCode,
  path,
  rowId,
})
export const removeBudgetRowError = (error) => ({
  type: REMOVE_BUDGET_ROW_ERROR,
  error,
})
export const removeBudgetRowSuccess = ({ tree, budgetId, rowId }) => ({
  type: REMOVE_BUDGET_ROW_SUCCESS,
  tree,
  budgetId,
  rowId,
})

export const saveBudgetRow = ({
  budgetId,
  companyCode,
  path,
  value,
  formulaWasModified,
  dv,
  end,
  start,
  view,
}) => ({
  type: SAVE_BUDGET_ROW,
  budgetId,
  companyCode,
  path,
  value,
  formulaWasModified,
  dv,
  end,
  start,
  view,
})
export const saveBudgetRowError = (error) => ({
  type: SAVE_BUDGET_ROW_ERROR,
  error,
})
export const saveBudgetRowSuccess = ({ data, tree }) => ({
  type: SAVE_BUDGET_ROW_SUCCESS,
  data,
  tree,
})

export const showRowNotes = ({ row, show }) => ({
  type: SHOW_ROW_NOTES,
  row,
  show,
})

export const showRowSettings = ({ row, parentGroup, show }) => ({
  type: SHOW_ROW_SETTINGS,
  row,
  parentGroup,
  show,
})

export const syncBudget = ({
  companyCode,
  budgetId,
  dv,
  end,
  start,
  view,
}) => ({ type: SYNC_BUDGET, companyCode, budgetId, dv, end, start, view })

export const syncBudgetError = (error) => ({ type: SYNC_BUDGET_ERROR, error })

export const syncBudgetSuccess = ({ companyCode, budgetId, tree }) => ({
  type: SYNC_BUDGET_SUCCESS,
  companyCode,
  budgetId,
  tree,
})

export const toggleBudgetModal = ({ modalType }) => ({
  type: TOGGLE_BUDGET_MODAL,
  modalType,
})

export const toggleShowFixedColumn = () => ({
  type: TOGGLE_FIXED_COLUMN,
})
