import { List, Record } from 'immutable'

const CompanyRecord = Record({
  accountingLockDate: null,
  businessId: '',
  children: List(),
  code: '',
  currencyCode: '',
  id: undefined,
  name: '',
  oVTCode: '',
  integrationSourceSystemId: undefined,
  accountNumMIN: undefined,
  accountNumMAX: undefined,
  allowLettersInAccountNumbers: false,
  customerLockDate: undefined,
  companyLockDate: undefined,
  level: 0, //calculated and used in companies list only. 1 means root level
  links: undefined,
  linksNavigationTitle: undefined,
  groupJoinDate: undefined,
  groupExitDate: undefined,
  parentId: undefined,
})

export default CompanyRecord
