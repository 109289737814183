import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { isArray } from 'lodash'

import { handleError } from 'api/api-utils'
import budgetApi from 'api/BudgetApi'

import {
  addBudgetRowError,
  addBudgetRowSuccess,
  editBudgetCellError,
  editBudgetCellSuccess,
  getBudget,
  getBudgetError,
  getBudgetSuccess,
  getBudgetFixedDataError,
  getBudgetFixedDataSuccess,
  getBudgetTreeError,
  getBudgetTreeSuccess,
  removeBudgetRowError,
  removeBudgetRowSuccess,
  saveBudgetRowError,
  saveBudgetRowSuccess,
} from './actions'

import {
  ADD_BUDGET_ROW,
  EDIT_BUDGET_CELL,
  EDIT_BUDGET_CELL_PATCH,
  GET_BUDGET,
  GET_BUDGET_FIXED_DATA,
  GET_BUDGET_TREE,
  REMOVE_BUDGET_ROW,
  SAVE_BUDGET_ROW,
  SYNC_BUDGET,
} from './constants'

import { findLastOrderNumber, fixLeadingSlash } from 'utils/schemeGroupUtils'

import { debounce } from 'utils/sagaDebounce'

const BUFFER_DELAY_IN_MS = 1000

// Individual exports for testing
export function* addBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    parentRow,
    value,
    dv,
    end,
    start,
    view,
  } = action
  const order = findLastOrderNumber(parentRow)

  try {
    const patch = [
      {
        op: 'add',
        path: fixLeadingSlash(`${parentRow.path}/children/-`),
        value: { ...value, order },
      },
    ]

    const tree = yield call(budgetApi.patchBudgetTree, {
      companyCode,
      budgetId,
      patch,
    })
    const data = yield call(budgetApi.getBudgetData, {
      budgetId,
      companyCode,
      dv,
      end,
      start,
      view,
    })
    yield put(addBudgetRowSuccess({ data, tree }))
  } catch (error) {
    yield put(handleError(error, addBudgetRowError))
  }
}

export function* editBudgetCell(action) {
  const { companyCode, budgetId, patches, dv, start, end, rowHasData } = action

  try {
    const data = yield call(budgetApi.patchBudgetData, {
      companyCode,
      budgetId,
      patch: patches,
      dv,
      start,
      end,
    })
    const summedValues = patches.reduce((sum, patch) => sum + patch.value, 0)
    if ((!rowHasData && summedValues) || (rowHasData && !summedValues)) {
      const tree = yield call(budgetApi.getBudgetTree, {
        companyCode,
        budgetId,
      })
      yield put(getBudgetTreeSuccess({ tree }))
    }
    // { companyCode, budgetId, dv, end, start, thePatch });
    yield put(editBudgetCellSuccess({ data }))
  } catch (error) {
    yield put(handleError(error, editBudgetCellError))
  }
}

const combineActions = (oldAction, newAction) => {
  const currentPatch = oldAction?.patches || []

  const { selection, value } = newAction
  const newPatch = isArray(value)
    ? mapMultipleCellValuesToPatch(value)
    : mapSelectionToPatch(selection, value)
  return {
    ...newAction,
    patches: [...currentPatch, ...newPatch],
  }
}

export const mapSelectionToPatch = (selection, value) =>
  selection
    .map((cell) => ({
      op: 'replace',
      path: `/rows/${cell.row}/${cell.column}/amount`,
      value,
    }))
    .toJS()

export const mapMultipleCellValuesToPatch = (values) =>
  values.map((v) => ({
    op: 'replace',
    path: `/rows/${v.row}/${v.column}/amount`,
    value: v.value,
  }))

// Use *Worker for name, to not override getBudget() action creator.
export function* getBudgetWorker(action) {
  try {
    const { budgetId, companyCode, dv, end, start, view } = action

    const [data, tree] = yield all([
      call(budgetApi.getBudgetData, {
        budgetId,
        companyCode,
        dv,
        end,
        start,
        view,
      }),
      call(budgetApi.getBudgetTree, { budgetId, companyCode }),
    ])
    yield put(getBudgetSuccess({ data, tree }))
  } catch (error) {
    yield put(handleError(error, getBudgetError))
  }
}

export function* getBudgetTree(action) {
  try {
    const { budgetId, companyCode } = action
    const tree = yield call(budgetApi.getBudgetTree, { budgetId, companyCode })
    yield put(getBudgetTreeSuccess({ budgetId, companyCode, tree }))
  } catch (error) {
    yield put(handleError(error, getBudgetTreeError))
  }
}

export function* removeBudgetRow(action) {
  const { budgetId, companyCode, path, rowId } = action

  try {
    const patch = [{ op: 'remove', path: fixLeadingSlash(path) }]
    const tree = yield call(budgetApi.patchBudgetTree, {
      companyCode,
      budgetId,
      patch,
    })
    yield put(removeBudgetRowSuccess({ tree, budgetId, rowId }))
  } catch (error) {
    yield put(handleError(error, removeBudgetRowError))
  }
}

export function* saveBudgetRow(action) {
  const {
    companyCode,
    budgetId,
    path,
    value,
    formulaWasModified,
    dv,
    end,
    start,
    view,
  } = action
  try {
    // patch must be an array

    // OPTION 1: Patch the entire row at path with the given value
    // Pros:
    // - It's easy. No need to worry about the props here
    // Cons:
    // - By definition, it will replace the entity at path, giving it a new identity.
    // - Formula's referencing the row will break because of changing identity!

    // const patchData = [
    //   {
    //     op: 'replace',
    //     path,
    //     value
    //   }
    // ];

    // OPTION 2: Patch each attribute
    // Pros:
    // - Identity of the row is persisted
    // Cons:
    // - Verbose.

    // This code chooses OPTION 2, because persisting identity is easier
    // to reason about.

    const patch = [
      {
        op: 'replace',
        path: fixLeadingSlash(`${path}/name`),
        value: value.name,
      },
      {
        op: 'replace',
        path: fixLeadingSlash(`${path}/rowType`),
        value: value.rowType,
      },
      {
        op: 'replace',
        path: fixLeadingSlash(`${path}/formula`),
        value: value.formula,
      },
    ]
    const tree = yield call(budgetApi.patchBudgetTree, {
      companyCode,
      budgetId,
      patch,
    })
    // In case calculation formula was changed, budget data must be fetched again
    if (formulaWasModified) {
      const data = yield call(budgetApi.getBudgetData, {
        budgetId,
        companyCode,
        dv,
        end,
        start,
        view,
      })

      yield put(saveBudgetRowSuccess({ data, tree }))
    } else {
      yield put(saveBudgetRowSuccess({ tree }))
    }
  } catch (error) {
    yield put(handleError(error, saveBudgetRowError))
  }
}

export function* applyBudgetSync(action) {
  try {
    const { budgetId, companyCode } = action

    // Apply the sync, returns new tree (just reject and reload budget tree & data :P)
    yield call(budgetApi.applyBudgetSync, { budgetId, companyCode })
    // Reload the budget with parameters provided.
    yield put(getBudget(action))
  } catch (error) {
    yield put(handleError(error, getBudgetTreeError))
  }
}

export function* getBudgetFixedData(action) {
  const { companyCode, budgetId } = action

  try {
    const data = yield call(budgetApi.getBudgetFixedData, {
      companyCode,
      budgetId,
    })
    yield put(getBudgetFixedDataSuccess({ rows: data.rows }))
  } catch (error) {
    yield put(handleError(error, getBudgetFixedDataError))
  }
}

// All sagas to be loaded
export function* budgetSaga() {
  yield all([
    takeEvery(ADD_BUDGET_ROW, addBudgetRow),
    takeEvery(EDIT_BUDGET_CELL_PATCH, editBudgetCell),
    takeLatest(GET_BUDGET, getBudgetWorker),
    takeEvery(GET_BUDGET_TREE, getBudgetTree),
    takeEvery(REMOVE_BUDGET_ROW, removeBudgetRow),
    takeEvery(SAVE_BUDGET_ROW, saveBudgetRow),
    takeLatest(SYNC_BUDGET, applyBudgetSync),
    takeLatest(GET_BUDGET_FIXED_DATA, getBudgetFixedData),
    debounce(
      BUFFER_DELAY_IN_MS,
      EDIT_BUDGET_CELL,
      editBudgetCell,
      combineActions
    ),
  ])
}

export default budgetSaga
